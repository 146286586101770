<template>
<div>
     <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="background: url('/images/company/aboutus.jpg');">
      <div class="bg-overlay "></div>
    </section><!--end section-->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- Hero End -->
    <section class="section">
        <div class="container">
            <div class="row align-items-center bg-custom" id="counter">
                <!--end col-->
                <div class="col-md-12 mt-4 pt-2 pb-4 mt-sm-0 pt-sm-0 ">
                    <div class="ms-lg-4">
                        <div class="section-title">
                          <b-img class="mt-3 mr-1" style="max-width: 500px" thumbnail fluid left :src="slide.image" alt="principal message"></b-img>
                          <h4 class="title text-custom mb-4">{{ slide.title }}</h4>
                          <div class="text-custom2" v-html="slide.description"></div>
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->
    </section><!--end section-->
</div>

</template>

<script>
import Vue from "vue";
import { BImg } from 'bootstrap-vue'
import {mapGetters} from "vuex";
Vue.component('b-img', BImg)
export default {
  name: 'Principal Message',
  props:{id:{required:true}},
  data(){
    return{
      slide:{},
    }
  },
  methods:{
    singleSlide(){
      this.slide=this.news_sliders.find(item=>item.id===parseInt(this.id));
    }
  },
  created() {
    if(this.sliders.length <1) this.$store.dispatch('GET_ALL_SLIDER');
    this.singleSlide();
  },
  computed:{
    ...mapGetters(['sliders','news_sliders']),
  },
  watch:{
    id(){
      this.singleSlide();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
